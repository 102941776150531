import React, { useEffect, useReducer } from "react";
import { nAuth } from "./interfaces";

const AuthDispatchContext = React.createContext<
  nAuth.tDispatchContext | undefined
>(undefined);
AuthDispatchContext.displayName = "AuthDispatchContext";
const AuthStateContext = React.createContext<nAuth.tStateContext | undefined>(
  undefined
);
AuthStateContext.displayName = "AuthStateContext";

const LS_DATA = "__noytrall-ga-token__";

const initialState: nAuth.tState = {
  data: {
    token: null,
    type: null,
  },
  status: "idle",
  error: null,
};

const reducer = (state: nAuth.tState, action: nAuth.tAction): nAuth.tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const AuthContextProvider: React.FC<nAuth.iContextProps> = ({ children }) => {
  const [state, dispatch]: [nAuth.tState, React.Dispatch<nAuth.tAction>] =
    useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "pending" });

    const stored = localStorage.getItem(LS_DATA);

    if (!stored) {
      return dispatch({ type: "set data", data: { token: null, type: null } });
    }
    if (
      window.location.pathname.includes("reset-password") ||
      window.location.pathname.includes("forgot")
    )
      return dispatch({ type: "set data", data: { token: null, type: null } });
    else {
      const data = JSON.parse(stored) as nAuth.tStateData;
      return dispatch({ type: "set data", data });
    }
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export { AuthContextProvider, AuthDispatchContext, AuthStateContext };
