import React, { useCallback } from "react";
import { AuthDispatchContext } from "../";
import { cleanStorage } from "../../../utils/storage";

const useAuthDispatch = () => {
  const dispatch = React.useContext(AuthDispatchContext);

  if (!dispatch)
    throw Error("useAuthDispatch must be used within AuthStateContext");

  const loginUser = useCallback(
    (token: string) => {
      dispatch({ type: "set data", data: { token, type: "user" } });
    },
    [dispatch]
  );
  const loginGuest = useCallback(
    (token: string) => {
      dispatch({ type: "set data", data: { token, type: "guest" } });
    },
    [dispatch]
  );
  const logout = useCallback(() => {
    cleanStorage();
    dispatch({ type: "set data", data: { token: null, type: null } });
  }, [dispatch]);

  return { loginGuest, loginUser, logout };
};

export default useAuthDispatch;
