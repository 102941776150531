import React, { Fragment } from "react";
import { Button, IconButton, Modal, ModalProps } from "rsuite";
import { ReactComponent as ArrowBackIcon } from "../../../assets/icons/arrow_back.svg";
import { ReactComponent as EnergyProgramSavingIcon } from "../../../assets/icons/energy_program_saving.svg";
import { ReactComponent as HotelClass1Icon } from "../../../assets/icons/hotel_class-1.svg";
import { ReactComponent as LocationOnIcon } from "../../../assets/icons/location_on.svg";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useScreenSize from "../../../hooks/useScreenSize";
import { tCertifiedHotel } from "../../../models/certifiedHotel";
import { COLORS } from "../../../utils/colors";
import { openNewTab } from "../../../utils/others";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import Flex from "../..//Flex";
import Icon from "../..//Icon";
import PageBottomPadding from "../..//PageBottomPadding";
import InterTag from "../..//Text/Inter";
import { hotelImageS3URL, tipologyDetails } from "./utils";

interface iHotelModalWrappedProps extends Pick<iProps, "onClose"> {
  hotelItem: tCertifiedHotel;
}

const HotelModalWrapped: React.FC<iHotelModalWrappedProps> = ({
  onClose,
  hotelItem,
}) => {
  const { trans } = useLocalizationState();
  const { root } = useScreenSize();

  const tipologyInfo = tipologyDetails[hotelItem.tipology];

  const certifications = () => {
    return (
      <Flex column gap={12}>
        <Flex row gap={4}>
          <Icon
            Element={EnergyProgramSavingIcon}
            size={24}
            fill={COLORS.secondary}
          />
          <InterTag
            text={trans("Certifications")}
            size={20}
            color={COLORS.secondary}
            bold
          />
        </Flex>
        <InterTag
          style={{ textWrap: "wrap" }}
          text={trans(
            "This accommodation has the following third party certifications:"
          )}
        />
        <Flex row gap={8} wrap>
          {hotelItem.certifications.map((c) => (
            <Flex
              key={c}
              color={COLORS.primary_100}
              style={{ borderRadius: "6px", padding: "4px 8px" }}
            >
              <InterTag text={c} size={12} color={COLORS.primary_900} />
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  };

  const info = () => {
    return (
      <Flex column gap={12}>
        <Flex column>
          <InterTag
            style={{ textWrap: "wrap" }}
            text={hotelItem.name}
            size={32}
            color={COLORS.secondary}
            bold
          />
          {hotelItem.address.length > 0 && (
            <Flex
              row
              gap={4}
              style={{ cursor: "pointer" }}
              onClick={() => {
                openNewTab(
                  `https://www.google.com/maps/search/${hotelItem.address
                    .split(" ")
                    .join("+")}`
                );
              }}
            >
              <Icon
                Element={LocationOnIcon}
                size={16}
                color={COLORS.secondary}
              />
              <InterTag
                wrap
                text={hotelItem.address}
                size={12}
                color={COLORS.secondary}
              />
            </Flex>
          )}
        </Flex>
        <Flex row gap={16}>
          <Flex one>
            {tipologyInfo ? (
              <Flex row middle gap={4}>
                <Icon
                  Element={tipologyInfo.icon}
                  fill={COLORS.secondary}
                  size={16}
                />
                <InterTag
                  size={14}
                  color={COLORS.secondary}
                  text={trans(tipologyInfo.label)}
                />
              </Flex>
            ) : (
              <div />
            )}
          </Flex>
          <Flex one>
            {hotelItem.classification > 0 ? (
              <Flex one row middle gap={4}>
                <InterTag
                  size={14}
                  color={COLORS.secondary}
                  text={hotelItem.classification}
                />
                <Icon
                  Element={HotelClass1Icon}
                  size={16}
                  fill={COLORS.secondary}
                />
              </Flex>
            ) : (
              <div />
            )}
          </Flex>
        </Flex>
        <InterTag
          wrap
          size={12}
          color={COLORS.secondary}
          text={hotelItem.description}
        />
      </Flex>
    );
  };

  return (
    <Fragment>
      <Modal.Body style={{ padding: 0, height: "100%", margin: 0 }}>
        <Flex column middle style={{ height: "100%" }}>
          <Flex
            style={{
              height: "100%",
              maxWidth: "500px",
              width: "100%",
              position: "relative",
              overflow: "auto",
              backgroundColor: COLORS.gray_100,
            }}
            gap={24}
            column
          >
            <img
              src={hotelImageS3URL(hotelItem.id)}
              alt={`${hotelItem.name}`}
            />
            <Flex column gap={24} style={{ padding: "0 16px" }}>
              {info()}
              {certifications()}
              <PageBottomPadding size={60} />
            </Flex>

            <div
              style={{
                position: "fixed",
                zIndex: 3,
                bottom: 0,
                ...(root ? (root.width <= 500 ? { left: 0 } : {}) : {}),
                width: "100%",
                maxWidth: "500px",
                backgroundColor: COLORS.white,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Flex row style={{ padding: "12px" }}>
                <IconButton
                  onClick={onClose}
                  icon={
                    <Icon
                      Element={ArrowBackIcon}
                      color={COLORS.secondary}
                      size={24}
                    />
                  }
                  circle
                  size="xs"
                  appearance="subtle"
                />

                <Flex row around one>
                  {hotelItem.website ? (
                    <Button
                      appearance="primary"
                      onClick={() => {
                        if (hotelItem.website) openNewTab(hotelItem.website);
                      }}
                    >
                      <InterTag
                        text={trans("Visit Website")}
                        bold
                        size={16}
                        color={COLORS.white}
                      />
                    </Button>
                  ) : null}
                  {hotelItem.booking_url ? (
                    <Button
                      style={{ backgroundColor: "#003B95" }}
                      appearance="primary"
                      onClick={() => {
                        if (hotelItem.booking_url)
                          openNewTab(`${hotelItem.booking_url}`);
                      }}
                    >
                      <InterTag
                        text={trans("Check Availability")}
                        bold
                        size={16}
                        color={COLORS.white}
                      />
                    </Button>
                  ) : null}
                </Flex>
              </Flex>
            </div>
          </Flex>
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  hotelItem: tCertifiedHotel | null;
}

const HotelModal: React.FC<iProps> = ({ onClose, hotelItem, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, overflow: false, onClose, ...props }}>
      {props.open && hotelItem !== null && (
        <HotelModalWrapped {...{ onClose, hotelItem }} />
      )}
    </Modal>
  );
};

export default HotelModal;
