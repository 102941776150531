import React, { Fragment, useState } from "react";
import { Button, IconButton, Modal, ModalProps } from "rsuite";
import { ReactComponent as AccountCircleIcon } from "../../../../../assets/icons/account_circle.svg";
import { ReactComponent as ArrowBackIcon } from "../../../../../assets/icons/arrow_back.svg";
import { ReactComponent as ContactSupportIcon } from "../../../../../assets/icons/contact_support.svg";
import { ReactComponent as FacebookIcon } from "../../../../../assets/icons/facebook.svg";
import { ReactComponent as GavelIcon } from "../../../../../assets/icons/gavel.svg";
import { ReactComponent as InstagramIcon } from "../../../../../assets/icons/instagram.svg";
import { ReactComponent as KeyboardArrowRightIcon } from "../../../../../assets/icons/keyboard_arrow_right.svg";
import { ReactComponent as PolicyIcon } from "../../../../../assets/icons/policy.svg";
import { ReactComponent as TwitterIcon } from "../../../../../assets/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";
import Flex from "../../../../../components/Flex";
import Icon, { tIcon, tIconElement } from "../../../../../components/Icon";
import { toIconProps } from "../../../../../components/Icon/utils";
import PrivacyNoticeModal from "../../../../../components/Modals/PrivacyNoticeModal";
import TermsOfServiceModal from "../../../../../components/Modals/TermsOfServiceModal";
import SimpleColoredButton from "../../../../../components/RsuiteWrapper/SimpleColoredButton";
import InterTag from "../../../../../components/Text/Inter";
import useAuthDispatch from "../../../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../../../utils/colors";
import { openNewTab } from "../../../../../utils/others";
import { DEFAULT_MODAL_PROPS } from "../../../../../utils/rsuite/modals";
import PersonalInformationModal from "./personalInformation";

interface iMenuWrappedProps extends Pick<iProps, "onClose"> {}

const MenuWrapped: React.FC<iMenuWrappedProps> = ({ onClose }) => {
  const { trans } = useLocalizationState();
  const { logout } = useAuthDispatch();
  const [personalModalState, setPersonalModalState] = useState<{
    open: boolean;
  }>({ open: false });
  const [termsModalState, setTermsModalState] = useState<{ open: boolean }>({
    open: false,
  });
  const [privacyModalState, setPrivacyModalState] = useState<{ open: boolean }>(
    { open: false }
  );

  const list: {
    label: string;
    children: { label: string; icon: tIcon | tIconElement; onClick(): void }[];
  }[] = [
    {
      label: trans("Settings"),
      children: [
        {
          icon: AccountCircleIcon,
          label: trans("Personal Information"),
          onClick() {
            setPersonalModalState({ open: true });
          },
        },
      ],
    },
    {
      label: trans("Social Media"),
      children: [
        {
          label: trans("Follow us on Facebook"),
          icon: FacebookIcon,
          onClick() {
            openNewTab("https://www.facebook.com/noytrall");
          },
        },
        {
          label: trans("Follow us on Instagram"),
          icon: InstagramIcon,
          onClick() {
            openNewTab("https://www.instagram.com/_noytrall/");
          },
        },
        {
          label: trans("Follow us on X"),
          icon: TwitterIcon,
          onClick() {
            openNewTab("https://x.com/_noytrall");
          },
        },
        {
          label: trans("Follow us on Youtube"),
          icon: YoutubeIcon,
          onClick() {
            openNewTab("https://www.youtube.com/@noytrall");
          },
        },
      ],
    },
    {
      label: trans("Support"),
      children: [
        {
          label: trans("Contact us"),
          icon: ContactSupportIcon,
          onClick() {
            openNewTab("mailto:help@noytrall.com");
          },
        },
      ],
    },
    {
      label: trans("Legal"),
      children: [
        {
          label: trans("Terms and conditions"),
          icon: GavelIcon,
          onClick() {
            setTermsModalState({ open: true });
          },
        },
        {
          label: trans("Privacy Policy"),
          icon: PolicyIcon,
          onClick() {
            setPrivacyModalState({ open: true });
          },
        },
      ],
    },
  ];

  return (
    <Fragment>
      <TermsOfServiceModal
        open={termsModalState.open}
        onClose={() => setTermsModalState({ open: false })}
        language="en"
      />
      <PrivacyNoticeModal
        open={privacyModalState.open}
        onClose={() => setPrivacyModalState({ open: false })}
        language="en"
      />
      <PersonalInformationModal
        open={personalModalState.open}
        onClose={() => setPersonalModalState({ open: false })}
      />
      <Modal.Body style={{ padding: 0, height: "100%", margin: 0 }}>
        <Flex column middle style={{ height: "100%" }}>
          <Flex
            style={{
              height: "100%",
              maxWidth: "500px",
              width: "100%",
              position: "relative",
              overflow: "auto",
              backgroundColor: COLORS.gray_100,
            }}
            column
          >
            <div
              style={{
                position: "fixed",
                zIndex: 3,
                top: 0,
                width: "100%",
                maxWidth: "500px",
                backgroundColor: COLORS.white,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Flex
                color={COLORS.white}
                row
                gap={24}
                middle
                style={{ padding: "8px" }}
              >
                <IconButton
                  onClick={onClose}
                  circle
                  size="xs"
                  appearance="subtle"
                  icon={
                    <Icon
                      Element={ArrowBackIcon}
                      size={24}
                      fill={COLORS.secondary}
                    />
                  }
                />
                <InterTag
                  size={20}
                  color={COLORS.secondary}
                  text={trans("Menu")}
                  bold
                />
              </Flex>
            </div>
            <Flex
              column
              gap={40}
              style={{
                marginTop: "70px",
                marginBottom: "50px",
                padding: "0 16px",
              }}
            >
              {list.map(({ children, label }) => (
                <Flex column gap={10} key={label}>
                  <InterTag
                    text={label}
                    size={20}
                    color={COLORS.secondary}
                    style={{ fontWeight: 500 }}
                  />
                  {children.map(({ icon, label, onClick }) => (
                    <Button key={label} appearance="subtle" onClick={onClick}>
                      <Flex row between style={{ width: "100%" }}>
                        <Flex row middle gap={8}>
                          <Icon
                            {...{
                              ...toIconProps(icon),
                              fill: COLORS.secondary,
                              size: 24,
                            }}
                          />
                          <InterTag
                            text={label}
                            size={16}
                            color={COLORS.secondary}
                          />
                        </Flex>
                        <Icon
                          Element={KeyboardArrowRightIcon}
                          size={24}
                          fill={COLORS.secondary}
                        />
                      </Flex>
                    </Button>
                  ))}
                </Flex>
              ))}
              <SimpleColoredButton
                onClick={logout}
                text={{
                  text: trans("Logout").toUpperCase(),
                  color: COLORS.white,
                }}
                color={COLORS.hot}
              />
            </Flex>
          </Flex>
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iProps extends ModalProps {}

const Menu: React.FC<iProps> = ({ ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, ...props }}>
      {props.open && <MenuWrapped {...{ onClose: props.onClose }} />}
    </Modal>
  );
};

export default Menu;
