import { LS_DATA_LANGUAGE } from "../context/Localization";

export const cleanStorage = () => {
  //   const version = localStorage.getItem(LS_VERSION);
  const langauge = localStorage.getItem(LS_DATA_LANGUAGE);
  localStorage.clear();
  sessionStorage.clear();
  //   if (version) localStorage.setItem(LS_VERSION, version);
  if (langauge) localStorage.setItem(LS_DATA_LANGUAGE, langauge);
};
