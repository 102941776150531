import axios from "axios";
import { find, round, sortBy } from "lodash";
import moment from "moment";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Loader } from "rsuite";
import { ReactComponent as JourneyAmico } from "../../../../assets/amicos/Journey-amico 1.svg";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as MoreVertIcon } from "../../../../assets/icons/more_vert.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import Flex from "../../../../components/Flex";
import Icon, { tIcon } from "../../../../components/Icon";
import AddCodeModal from "../../../../components/Modals/AddCode";
import StayConsumptionModal from "../../../../components/Modals/StayConsumptionModal";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import SimpleColoredButton from "../../../../components/RsuiteWrapper/SimpleColoredButton";
import SimpleWhisperPopoverDropdown from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../../components/Text/Inter";
import useAuthDispatch from "../../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../../hooks/apiRequests/useDeleteRequest";
import useGetRequest, {
  tUseGetRequest,
} from "../../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import useSimpleToaster from "../../../../hooks/useSimpleToaster";
import tHotel from "../../../../models/hotel";
import tReservation, { tStay } from "../../../../models/reservation";
import { shouldLogout } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { calcNumberOfNights } from "../../../../utils/dates";
import { getErrorMessage } from "../../../../utils/httpResponses";

type tRequest = {
  stays: {
    past: tReservation[];
    ongoing: tReservation[];
    upcoming: tReservation[];
  };
  hotels: tHotel[];
};

interface iPreviousUpcomingStaysProps {
  request: tUseGetRequest<tRequest>;
  dissociateStay(stayId: tStay["_id"]): void;
}

const PreviousUpcomingStays: React.FC<iPreviousUpcomingStaysProps> = ({
  request,
  dissociateStay,
}) => {
  const { trans } = useLocalizationState();
  const [selected, setSelected] = useState(0);
  const [stayModalState, setStayModalState] = useState<{
    open: boolean;
    stay: tStay | null;
    hotel: tHotel | null;
  }>({
    open: false,
    stay: null,
    hotel: null,
  });

  const dissociateButton = useCallback(
    (stay: tStay) => {
      return (
        <div>
          <SimpleWhisperPopoverDropdown
            placement="leftStart"
            options={[
              {
                key: "delete",
                label: { text: trans("Remove Stay"), color: COLORS.error },
                icon: { Element: DeleteIcon, fill: COLORS.error },
                onClick() {
                  dissociateStay(stay._id);
                },
              },
            ]}
          >
            <IconButton
              appearance="subtle"
              onClick={(e) => {
                e.stopPropagation();
              }}
              circle
              size="xs"
              icon={
                <Icon
                  Element={MoreVertIcon}
                  size={16}
                  fill={COLORS.secondary}
                />
              }
            />
          </SimpleWhisperPopoverDropdown>
        </div>
      );
    },
    [dissociateStay, trans]
  );

  const pastStays = useMemo(() => {
    return (
      <Flex column gap={16}>
        {request.data.stays.past.map((s) => {
          const hotel =
            find(request.data.hotels, (h) => h._id === s.hotel) || null;

          return (
            <Flex
              key={s._id}
              row
              color={COLORS.white}
              style={{ borderRadius: "8px" }}
              onClick={() => setStayModalState({ open: true, stay: s, hotel })}
            >
              {hotel && hotel.images.length > 0 && (
                <div>
                  <img
                    width={90}
                    height={90}
                    src={hotel.images[0]}
                    alt={"hotel"}
                  />
                </div>
              )}
              <Flex one row style={{ padding: "8px 12px" }}>
                <Flex column between one>
                  <InterTag
                    text={hotel?.name || ""}
                    size={16}
                    color={COLORS.secondary}
                  />
                  <InterTag
                    text={`${moment(s.startAt).format(
                      "MMMM DD, YYYY"
                    )} - ${moment(s.endAt).format("MMMM DD, YYYY")}`}
                    size={12}
                    color={COLORS.gray}
                  />
                  <InterTag
                    text={`#${s.code}`}
                    size={12}
                    color={COLORS.primary}
                  />
                  <Flex row left>
                    <Flex row gap={4} middle>
                      <Icon
                        Element={EcoIcon}
                        fill={COLORS.emissions}
                        size={24}
                      />
                      <InterTag
                        text={`${round(s.consumption.co2, 2)} kg`}
                        size={16}
                        color={COLORS.secondary}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex center>{dissociateButton(s)}</Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  }, [request.data, dissociateButton]);

  const upcomingStays = useMemo(() => {
    return (
      <Flex column gap={16}>
        {request.data.stays.upcoming.map((s) => {
          const hotel = find(request.data.hotels, (h) => h._id === s.hotel);

          return (
            <Flex
              onClick={(e: any) => {
                console.log("e.target :>> ", e.target);
              }}
              key={s._id}
              row
              color={COLORS.white}
              style={{ borderRadius: "8px" }}
            >
              {hotel && hotel.images.length > 0 && (
                <div>
                  <img
                    width={90}
                    height={90}
                    src={hotel.images[0]}
                    alt={"hotel"}
                  />
                </div>
              )}
              <Flex row one style={{ padding: "8px 12px" }}>
                <Flex column one between>
                  <InterTag
                    text={hotel?.name || ""}
                    size={16}
                    color={COLORS.secondary}
                  />
                  <InterTag
                    text={`${moment(s.startAt).format(
                      "MMMM DD, YYYY"
                    )} - ${moment(s.endAt).format("MMMM DD, YYYY")}`}
                    size={12}
                    color={COLORS.gray}
                  />
                  <InterTag
                    text={`#${s.code}`}
                    size={12}
                    color={COLORS.primary}
                  />
                </Flex>
                <Flex column>{dissociateButton(s)}</Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  }, [request.data, dissociateButton]);

  const segments: {
    key: keyof tRequest["stays"];
    label: string;
    list: JSX.Element;
  }[] = [
    { key: "past", label: trans("Previous Stays"), list: pastStays },
    { key: "upcoming", label: trans("Upcoming Stays"), list: upcomingStays },
  ];

  const { list } = segments[selected];

  return (
    <Fragment>
      <StayConsumptionModal
        open={stayModalState.open}
        onClose={() =>
          setStayModalState({ open: false, stay: null, hotel: null })
        }
        stay={stayModalState.stay}
        hotel={stayModalState.hotel}
      />
      <Flex column gap={16}>
        <Flex row between gap={4}>
          {segments.map(({ key, label }, i) => {
            return (
              <Flex one key={key}>
                <Button
                  onClick={() => selected !== i && setSelected(i)}
                  style={{ width: "100%" }}
                  appearance={selected === i ? "ghost" : "subtle"}
                  key={key}
                >
                  <InterTag
                    text={`${label} (${request.data.stays[key].length})`}
                    size={16}
                    bold
                    color={COLORS.secondary}
                  />
                </Button>
              </Flex>
            );
          })}
        </Flex>
        {list}
      </Flex>
    </Fragment>
  );
};

interface iOngoingStaysProps {
  dissociateStay(stayId: tStay["_id"]): void;
  request: tUseGetRequest<tRequest>;
  runRequest(): void;
}

const OngoingStays: React.FC<iOngoingStaysProps> = ({
  request,
  runRequest,
  dissociateStay,
}) => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const [modalState, setModalState] = useState<{ open: boolean }>({
    open: false,
  });

  if (request.data.stays.ongoing.length === 0) {
    if (request.data.stays.upcoming.length === 0)
      return (
        <Fragment>
          <AddCodeModal
            open={modalState.open}
            onClose={(action) => {
              if (action === "create") runRequest();
              setModalState({ open: false });
            }}
          />
          <Flex column middle gap={16}>
            <InterTag
              text={trans("Let's go out.")}
              size={40}
              color={COLORS.secondary}
            />
            <InterTag
              color={COLORS.secondary}
              size={16}
              style={{
                textAlign: "center",
                textWrap: "wrap",
                lineHeight: "18px",
              }}
              text={trans(
                "It seems you don't have any upcoming reservations at the moment."
              )}
            />
            <InterTag
              text={trans("Let's find you a sustainable one.")}
              size={16}
              color={COLORS.secondary}
            />
            <Button
              style={{ width: "100%", padding: "16px 0" }}
              onClick={() => navigate("/explore")}
              appearance="primary"
            >
              <InterTag
                color={COLORS.white}
                size={16}
                text={trans("Start Exploring").toUpperCase()}
              />
            </Button>
            <Icon Element={JourneyAmico} size={276} />
            <InterTag
              text={trans("Or if you have a booking code add it here.")}
              color={COLORS.secondary}
              size={16}
            />
            <SimpleColoredButton
              onClick={() => setModalState({ open: true })}
              text={{
                size: 16,
                text: trans("Add Code").toUpperCase(),
                color: COLORS.primary,
              }}
              color={COLORS.white}
              style={{ padding: "16px 0" }}
            />
          </Flex>
        </Fragment>
      );

    const stay = request.data.stays.upcoming[0];
    const hotel = find(request.data.hotels, (h) => h._id === stay.hotel)!;
    const numberOfNights = calcNumberOfNights(stay.startAt, stay.endAt);

    return (
      <Flex column gap={16}>
        <Flex column>
          <InterTag
            text={`${trans("Upcoming Stay")}`}
            bold
            size={20}
            color={COLORS.secondary}
          />
          <InterTag
            size={12}
            color={COLORS.gray}
            text={`${numberOfNights} nights • ${moment(stay.startAt).format(
              "MMMM DD, YYYY"
            )} to ${moment(stay.endAt).format("MMMM DD, YYYY")}`}
          />
        </Flex>
        <Flex column color={COLORS.white} style={{ borderRadius: "8px" }}>
          {hotel.images.length > 0 && (
            <Flex
              center
              middle
              color={COLORS.gray_400}
              style={{
                position: "relative",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: 1,
                  top: 8,
                  right: 8,
                }}
              >
                <SimpleWhisperPopoverDropdown
                  placement="leftStart"
                  options={[
                    {
                      key: "delete",
                      label: {
                        text: trans("Remove Stay"),
                        color: COLORS.error,
                      },
                      icon: { Element: DeleteIcon, fill: COLORS.error },
                      onClick() {
                        dissociateStay(stay._id);
                      },
                    },
                  ]}
                >
                  <IconButton
                    circle
                    size="xs"
                    icon={
                      <Icon
                        Element={MoreVertIcon}
                        size={16}
                        fill={COLORS.secondary}
                      />
                    }
                  />
                </SimpleWhisperPopoverDropdown>
              </div>
              <img
                style={{
                  maxWidth: "100%",
                }}
                height={169}
                width={"auto"}
                src={hotel.images[0]}
                alt={hotel.name}
              />
            </Flex>
          )}
          <Flex column gap={16} style={{ padding: "16px" }}>
            <div>
              <InterTag text={hotel.name} color={COLORS.secondary} size={24} />
              <InterTag
                wrap
                text={hotel.location.address}
                size={14}
                color={COLORS.gray}
              />
            </div>
            <Flex row around>
              {[
                { label: trans("Check-in"), date: stay.startAt },
                { label: trans("Check-out"), date: stay.endAt },
              ].map(({ date, label }) => (
                <Flex column middle key={label}>
                  <InterTag size={16} color={COLORS.gray} text={label} />
                  <InterTag
                    size={16}
                    color={COLORS.secondary}
                    text={moment(date).format("MMMM DD, YYYY")}
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  const stay = request.data.stays.ongoing[0];
  const hotel = find(request.data.hotels, (h) => h._id === stay.hotel)!;

  const numberOfNights = calcNumberOfNights(stay.startAt, stay.endAt);

  const {
    measures: { te, tw },
    co2,
  } = stay.consumption;

  const electricityConverted = convertEnergyUnit(te || 0);
  const waterConverted = convertVolumeUnit(tw || 0, { forceUnit: "l" });
  const co2Converted = convertMassUnit(co2 || 0);

  const consumption: {
    key: string;
    icon: tIcon;
    converted: nUnitConverter.tUnitConverterFunction;
  }[] = [
    {
      key: "electricity",
      icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
      converted: electricityConverted,
    },
    {
      key: "water",
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      converted: waterConverted,
    },
    {
      key: "co2",
      icon: { Element: EcoIcon, fill: COLORS.emissions },
      converted: co2Converted,
    },
  ];

  return (
    <Flex column gap={16}>
      <Flex column>
        <InterTag
          text={`${trans("Ongoing Stay")} (${
            request.data.stays.ongoing.length
          })`}
          bold
          size={20}
          color={COLORS.secondary}
        />
        <InterTag
          size={12}
          color={COLORS.gray}
          text={`${numberOfNights} nights • ${moment(stay.startAt).format(
            "MMMM DD, YYYY"
          )} to ${moment(stay.endAt).format("MMMM DD, YYYY")}`}
        />
      </Flex>
      <Flex column color={COLORS.white} style={{ borderRadius: "8px" }}>
        {hotel.images.length > 0 && (
          <Flex
            center
            middle
            color={COLORS.gray_400}
            style={{
              position: "relative",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <div
              style={{
                position: "absolute",
                zIndex: 1,
                top: 8,
                right: 8,
              }}
            >
              <SimpleWhisperPopoverDropdown
                placement="leftStart"
                options={[
                  {
                    key: "delete",
                    label: { text: trans("Remove Stay"), color: COLORS.error },
                    icon: { Element: DeleteIcon, fill: COLORS.error },
                    onClick() {
                      dissociateStay(stay._id);
                    },
                  },
                ]}
              >
                <IconButton
                  circle
                  size="xs"
                  icon={
                    <Icon
                      Element={MoreVertIcon}
                      size={16}
                      fill={COLORS.secondary}
                    />
                  }
                />
              </SimpleWhisperPopoverDropdown>
            </div>
            <img
              style={{
                maxWidth: "100%",
              }}
              height={169}
              width={"auto"}
              src={hotel.images[0]}
              alt={hotel.name}
            />
          </Flex>
        )}
        <Flex column gap={16} style={{ padding: "16px" }}>
          <div>
            <InterTag text={hotel.name} color={COLORS.secondary} size={24} />
            <InterTag
              wrap
              text={hotel.location.address}
              size={14}
              color={COLORS.gray}
            />
          </div>
          <Flex row around>
            {consumption.map(({ key, icon, converted }) => (
              <Flex key={key} column gap={4} middle>
                <Icon {...{ size: 24, ...icon }} />
                <InterTag
                  text={`${round(converted.value, 2)} ${converted.unitInHTML}`}
                  asHTML
                  size={16}
                  color={COLORS.secondary}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

interface iTitleProps {
  runRequest: () => void;
}

const Title: React.FC<iTitleProps> = ({ runRequest }) => {
  const { trans } = useLocalizationState();
  const [modalState, setModalState] = useState<{ open: boolean }>({
    open: false,
  });

  return (
    <Fragment>
      <AddCodeModal
        open={modalState.open}
        onClose={(action) => {
          if (action === "create") runRequest();
          setModalState({ open: false });
        }}
      />
      <Flex row between>
        <InterTag
          text={trans("Stays")}
          size={40}
          color={COLORS.secondary}
          bold
        />
        <Flex middle>
          <IconButton
            onClick={() => setModalState({ open: true })}
            appearance="primary"
            icon={<Icon size={24} fill={COLORS.white} Element={AddIcon} />}
          />
        </Flex>
      </Flex>
    </Fragment>
  );
};

const Stays: React.FC = () => {
  const toaster = useSimpleToaster();
  const { logout } = useAuthDispatch();
  const { trans } = useLocalizationState();
  const request = useGetRequest<tRequest>({
    stays: { ongoing: [], past: [], upcoming: [] },
    hotels: [],
  });
  const dissociateRequest = useDeleteRequest();

  const run = useCallback(() => {
    request.pending();
    axios
      .get(`/guest-app/v1/stays/hotels`)
      .then((res) => {
        const {
          data: { hotels, stays },
        } = res;

        const past: tReservation[] = [];
        const ongoing: tReservation[] = [];
        const upcoming: tReservation[] = [];

        (stays as tReservation[]).forEach((s) => {
          const { startAt, endAt } = s;

          if (moment(startAt).isAfter(moment())) upcoming.push(s);
          else if (moment(endAt).isAfter(moment())) ongoing.push(s);
          else past.push(s);
        });

        request.resolve({
          hotels,
          stays: {
            past: sortBy(past, (s) => -moment(s.startAt).valueOf()),
            ongoing: sortBy(ongoing, (s) => moment(s.startAt).valueOf()),
            upcoming: sortBy(upcoming, (s) => moment(s.startAt).valueOf()),
          },
        });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error, true);
        if (shouldLogout(err)) logout();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trans]);

  useEffectSafe(() => {
    run();
  }, [run]);

  const dissociateStay = useCallback(
    (stayId: tStay["_id"]) => {
      request.pending();
      dissociateRequest.pending();
      axios
        .delete(`/guest-app/v2/users/user/stays/${stayId}`)
        .then((res) => {
          toaster.success(trans("Stay Dissociated"));
          dissociateRequest.resolve();
          run();
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          toaster.error(error);
          dissociateRequest.reject(error);
          request.reject();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [run, trans]
  );

  return (
    <Fragment>
      <Flex column style={{ padding: "32px 16px 0 16px" }} gap={24}>
        <Title runRequest={run} />
        {request.isLoading ? (
          <Flex center middle>
            <Loader size="lg" />
          </Flex>
        ) : (
          <>
            <OngoingStays
              request={request}
              runRequest={run}
              dissociateStay={dissociateStay}
            />
            <PreviousUpcomingStays
              request={request}
              dissociateStay={dissociateStay}
            />
          </>
        )}
        <PageBottomPadding />
      </Flex>
    </Fragment>
  );
};

export default Stays;
